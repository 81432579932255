import { useQueryClient } from "@tanstack/react-query";
import { useLoginRefreshMutation } from "api/queries/login";
import { userQueryKeys } from "api/queries/user";
import { IconExclamationTriangle } from "assets/icons";
import { Form } from "components/form";
import { InfoWell } from "components/info-well";
import { Column } from "components/layout";
import { Link } from "components/link";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { INFO_AUTH_EMPTY, INFO_AUTH_EXPIRED } from "strings";
import { getTokens, removeTokens, storeTokens } from "utils/storage";
import { useNextSearchParam } from "utils/use-next-search-param";

export const NotAuthorizedView = () => {
  const next = useNextSearchParam();

  const wasAuthed = useMemo(() => !!getTokens()?.accessToken, []);

  const client = useQueryClient();
  const navigate = useNavigate();
  const loginRefreshMutation = useLoginRefreshMutation({
    onSuccess: async ({ AuthenticationResult }) => {
      storeTokens(
        AuthenticationResult?.AccessToken,
        AuthenticationResult?.RefreshToken
      );
      await client.invalidateQueries(userQueryKeys.user);
    },
    onError: async () => {
      removeTokens();
      await client.invalidateQueries(userQueryKeys.user);
      navigate(`/login/?next=${next}`);
    },
  });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    loginRefreshMutation.mutate();
  };

  if (wasAuthed) {
    return (
      <Column gap="2">
        <InfoWell
          icon={<IconExclamationTriangle />}
          iconColor="warning"
          title="Session Expired"
          description={[INFO_AUTH_EXPIRED]}
        />
        <Form onSubmit={handleSubmit}>
          <button type="submit">Reauthenticate</button>
        </Form>
      </Column>
    );
  }

  return (
    <Column gap="2">
      <InfoWell
        icon={<IconExclamationTriangle />}
        iconColor="warning"
        title="Not Logged In"
        description={[INFO_AUTH_EMPTY]}
        actions={[
          <Link key="login" to={`/login/?next=${next}`}>
            Login
          </Link>,
        ]}
      />
    </Column>
  );
};
