import { Navigate, useSearchParams } from "react-router-dom";
import { removeTokens } from "utils/storage";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { userQueryKeys } from "api/queries/user";
import { LoadingView } from "views/fragments/loading";
import { useEffect } from "react";

export const LogoutView = () => {
  const client = useQueryClient();
  const [searchParams] = useSearchParams();
  const next = searchParams.get("next");
  const to = next ? `/login/?next=${next}` : "/";
  const logoutMutation = useMutation({
    mutationFn: async () => {
      removeTokens();
      await client.invalidateQueries(userQueryKeys.user);
    },
  });

  useEffect(() => {
    logoutMutation.mutate();
  }, [logoutMutation]);

  if (logoutMutation.isSuccess) return <Navigate to={to} />;

  return <LoadingView />;
};
