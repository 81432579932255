import { useLoginForgotPasswordMutation } from "api/queries/login";
import { IconSkullCrossbones } from "assets/icons";
import { Form } from "components/form";
import { InfoWell } from "components/info-well";
import { Column } from "components/layout";
import { useNavigate } from "react-router-dom";
import { ERROR_FORGOT_PASSWORD_FAILED } from "strings";

export const ForgotPasswordView = () => {
  const navigate = useNavigate();
  const loginForgotPasswordMutation = useLoginForgotPasswordMutation({
    onSuccess: (_, { username }) => {
      navigate(`confirm/?username=${username}`);
    },
  });
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const username = formData.get("username")?.toString();
    loginForgotPasswordMutation.mutate({ username });
  };
  return (
    <Column gap="2">
      {loginForgotPasswordMutation.isError && (
        <InfoWell
          icon={<IconSkullCrossbones />}
          iconColor="error"
          title="Error"
          description={[
            ERROR_FORGOT_PASSWORD_FAILED,
            loginForgotPasswordMutation.error?.message,
          ]}
        />
      )}
      <Form onSubmit={handleSubmit}>
        <Column gap="1">
          <label id="username-field-label" htmlFor="username">
            Username
          </label>
          <input
            id="username"
            name="username"
            type="text"
            autoComplete="username"
            aria-labelledby="username-field-label"
          />
        </Column>
        <button type="submit" disabled={loginForgotPasswordMutation.isLoading}>
          Forgot Password
        </button>
      </Form>
    </Column>
  );
};
