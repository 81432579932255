import { IconExclamationTriangle } from "assets/icons";
import { InfoWell } from "components/info-well";
import { ERROR_NOT_FOUND } from "strings";

export const NotFoundView = () => (
  <InfoWell
    icon={<IconExclamationTriangle />}
    iconColor="warning"
    title="Not Found"
    description={[ERROR_NOT_FOUND]}
  />
);
