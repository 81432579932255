import { useLogoutMutation } from "api/queries/logout";
import { IconExclamationCircle, IconUserSecret } from "assets/icons";
import { Button } from "components/button";
import { Icon } from "components/icon";
import { InfoWell } from "components/info-well";
import { Box, Column, Row } from "components/layout";
import { Link } from "components/link";
import { UIText } from "components/ui-text";
import { useAuth } from "contexts/auth/auth-context";
import { Navigate } from "react-router-dom";
import { CONFIRM_LOGOUT_EVERYWHERE } from "strings";
import { createGravatarUrl } from "utils/gravatar";

export const UserView = () => {
  const auth = useAuth();

  const logoutMutation = useLogoutMutation();
  const handleClickLogoutEverywhere: React.MouseEventHandler<HTMLButtonElement> = () => {
    const confirm = window.confirm(CONFIRM_LOGOUT_EVERYWHERE);
    if (confirm) logoutMutation.mutate();
  };

  if (logoutMutation.isSuccess) return <Navigate to="/" />;

  return (
    <Column borderWidth="1px" borderStyle="solid" borderColor="bg.0" borderRadius="2" p="2" gap="2">
      <Row gap="2">
        <Column flex="0 0 auto">
          {auth.user?.email ? (
            <Box as="img" width="1" height="1" borderRadius="0" src={createGravatarUrl(auth.user.email)} />
          ) : (
            <Icon width="1" height="1" fontSize="4" bg="bg.0" glyph={<IconUserSecret />} />
          )}
        </Column>

        <Column as="dl" gap="1" flex="1">
          <Column>
            <UIText as="dt">Username</UIText>
            <UIText as="dd" truncate={true}>
              {auth.user?.username}
            </UIText>
          </Column>
          <Column>
            <UIText as="dt">Email</UIText>
            <UIText as="dd" truncate={true}>
              {auth.user?.email ?? "None"}
            </UIText>
          </Column>
        </Column>
      </Row>
      <Column gap="1">
        <Button as={Link} to="/sonos/link">
          Link Sonos
        </Button>
        <Button as={Link} to="change-password">
          Change Password
        </Button>
        <Button as={Link} to="logout">
          Logout
        </Button>
        <InfoWell
          icon={<IconExclamationCircle />}
          iconColor="warning"
          title="Warning"
          description={[
            "This will logout all devices connected to your account.",
            "You probably don't want to do this.",
          ]}
          actions={[
            <button type="button" onClick={handleClickLogoutEverywhere}>
              Logout Everywhere
            </button>,
          ]}
        />
      </Column>
    </Column>
  );
};
