import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { LINK_SONOS_ENDPOINT } from "api/urls";
import invariant from "tiny-invariant";

export const useSonosLinkMutation = (
  options?: UseMutationOptions<
    void,
    Error,
    { accessToken?: string; refreshToken?: string; linkCode?: string }
  >
) =>
  useMutation({
    mutationFn: async ({ accessToken, refreshToken, linkCode }) => {
      invariant(
        accessToken,
        "[useSonosLinkMutation] mutation requires an access token"
      );
      invariant(
        refreshToken,
        "[useSonosLinkMutation] mutation requires a refresh token"
      );
      invariant(
        linkCode,
        "[useSonosLinkMutation] mutation requires a sonos link code"
      );
      const response = await fetch(LINK_SONOS_ENDPOINT, {
        method: "POST",
        headers: [
          ["Authorization", `Bearer ${accessToken}`],
          ["Content-Type", "application/json"],
          ["Accept", "application/json"],
        ],
        body: JSON.stringify({
          link_code: linkCode,
          access_token: accessToken,
          refresh_token: refreshToken,
        }),
      });
      switch (response.status) {
        case 400:
        case 403:
        case 404: {
          const json = await response.json();
          throw new Error(json?.message ?? json?.detail);
        }
        case 200: {
          return;
        }
        default: {
          throw new Error(response.status.toString());
        }
      }
    },
    ...options,
  });
