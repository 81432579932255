import { useNavigate, useSearchParams } from "react-router-dom";
import { IconSkullCrossbones } from "assets/icons";
import { InfoWell } from "components/info-well";
import { Column } from "components/layout";
import { storeTokens } from "utils/storage";
import { useLoginChallengeMutation } from "api/queries/login";
import { Form } from "components/form";
import { ERROR_LOGIN_CHALLENGE_FAILED } from "strings";

export const LoginChallengeView = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const loginChallengeMutation = useLoginChallengeMutation({
    onSuccess: ({ AuthenticationResult }) => {
      if (AuthenticationResult) {
        const next = searchParams.get("next") ?? "/";
        storeTokens(
          AuthenticationResult?.AccessToken,
          AuthenticationResult?.RefreshToken
        );
        navigate(next);
      }
    },
  });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const username = formData.get("username")?.toString();
    const session = formData.get("session")?.toString();
    const newPassword = formData.get("new-password")?.toString();
    const confirmPassword = formData.get("confirm-password")?.toString();
    loginChallengeMutation.mutate({
      newPassword,
      confirmPassword,
      session,
      username,
    });
  };

  return (
    <Column gap="2">
      {loginChallengeMutation.isError && (
        <InfoWell
          icon={<IconSkullCrossbones />}
          iconColor="error"
          title="Error"
          description={[
            ERROR_LOGIN_CHALLENGE_FAILED,
            loginChallengeMutation.error?.message,
          ]}
        />
      )}
      <Form onSubmit={handleSubmit}>
        <Column gap="1">
          <label htmlFor="username">Username</label>
          <input
            id="username"
            name="username"
            type="text"
            autoComplete="username"
            defaultValue={searchParams.get("username")?.toString() ?? ""}
          />
        </Column>
        <Column gap="1">
          <label htmlFor="session">Session</label>
          <input
            id="session"
            name="session"
            type="text"
            defaultValue={searchParams.get("session")?.toString() ?? ""}
          />
        </Column>
        <Column gap="1">
          <label htmlFor="new-password">New password</label>
          <input
            id="new-password"
            name="new-password"
            type="password"
            autoComplete="new-password"
          />
        </Column>
        <Column gap="1">
          <label htmlFor="confirm-password">Confirm password</label>
          <input
            id="confirm-password"
            name="confirm-password"
            type="password"
            autoComplete="new-password"
          />
        </Column>
        <button type="submit">Set Password</button>
      </Form>
    </Column>
  );
};
