import { useUserChangePasswordMutation } from "api/queries/user";
import { IconCheckCircle, IconSkullCrossbones } from "assets/icons";
import { Form } from "components/form";
import { InfoWell } from "components/info-well";
import { Column } from "components/layout";
import { SUCCESS_PASSWORD_CHANGED } from "strings";
import { getTokens } from "utils/storage";

export const ChangePasswordView = () => {
  const userChangePasswordMutation = useUserChangePasswordMutation({
    onSuccess: async () => {},
    onError: async () => {},
  });
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const accessToken = getTokens().accessToken;
    const formData = new FormData(event.currentTarget);
    const currentPassword = formData.get("current-password")?.toString();
    const newPassword = formData.get("new-password")?.toString();
    const confirmPassword = formData.get("confirm-password")?.toString();
    userChangePasswordMutation.mutate({
      accessToken,
      currentPassword,
      newPassword,
      confirmPassword,
    });
  };
  return (
    <Column gap="2">
      {userChangePasswordMutation.isError && (
        <InfoWell
          icon={<IconSkullCrossbones />}
          iconColor="error"
          title="Error"
          description={[userChangePasswordMutation.error?.message]}
        />
      )}
      {userChangePasswordMutation.isSuccess && (
        <InfoWell
          icon={<IconCheckCircle />}
          iconColor="success"
          title="Success"
          description={[SUCCESS_PASSWORD_CHANGED]}
        />
      )}
      <Form onSubmit={handleSubmit}>
        <Column gap="1">
          <label htmlFor="current-password">Current Password</label>
          <input
            id="current-password"
            name="current-password"
            type="password"
            autoComplete="current-password"
          />
        </Column>
        <Column gap="1">
          <label htmlFor="new-password">New Password</label>
          <input
            id="new-password"
            name="new-password"
            type="password"
            autoComplete="new-password"
          />
        </Column>
        <Column gap="1">
          <label htmlFor="confirm-password">Confirm Password</label>
          <input
            id="confirm-password"
            name="confirm-password"
            type="password"
            autoComplete="new-password"
          />
        </Column>
        <button type="submit">Change Password</button>
      </Form>
    </Column>
  );
};
