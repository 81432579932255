import { Column } from "./layout";

export const Form = (props: {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  children: React.ReactNode[] | React.ReactNode;
}) => {
  return (
    <Column
      as="form"
      onSubmit={props.onSubmit}
      gap="2"
      p="2"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="bg.0"
      borderRadius="2"
    >
      {props.children}
    </Column>
  );
};
