import { IconCheckCircleSolid } from "assets/icons";
import { Form } from "components/form";
import { InfoWell } from "components/info-well";
import { Column } from "components/layout";
import { useNavigate } from "react-router-dom";
import { SUCCESS_SONOS_LINKED } from "strings";

export const SonosLinkSuccessView = () => {
  const navigate = useNavigate();
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    navigate("/user/logout");
  };
  return (
    <Column gap="2">
      <Form aria-label="Finish Sonos Link form" onSubmit={handleSubmit}>
        <InfoWell
          icon={<IconCheckCircleSolid />}
          iconColor="success"
          title="Linking Successful"
          description={[SUCCESS_SONOS_LINKED]}
        />
        <button type="submit">Finish</button>
      </Form>
    </Column>
  );
};
