import { ChangePasswordCommandOutput } from "@aws-sdk/client-cognito-identity-provider";
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { commands } from "api/commands";
import {
  ACCESS_TOKEN_STORAGE_KEY,
  ERROR_CONFIRM_PASSWORD,
  ERROR_PASSWORD_LENGTH,
  ERROR_PASSWORD_MISMATCH,
} from "strings";
import invariant from "tiny-invariant";
import { getItem } from "utils/storage";

export interface User {
  username: string;
  email?: string;
}

export const userQueryKeys = {
  user: ["auth", "user"],
};

export const useUserQuery = (options?: UseQueryOptions<{ user?: User }>) =>
  useQuery({
    queryKey: userQueryKeys.user,
    queryFn: async () => {
      try {
        const accessToken = getItem<string>(ACCESS_TOKEN_STORAGE_KEY);
        invariant(accessToken, "[authQuery] access token is missing");

        const output = await commands.getUser({ accessToken });
        invariant(
          output.$metadata.httpStatusCode === 200,
          "[authQuery] response not ok"
        );
        return {
          user: {
            username: output.Username!,
            email: output?.UserAttributes?.find((a) => a.Name === "email")
              ?.Value,
          },
        };
      } catch {
        return { user: undefined };
      }
    },
    ...options,
  });

export const useUserChangePasswordMutation = (
  options?: UseMutationOptions<
    ChangePasswordCommandOutput,
    Error,
    {
      accessToken?: string;
      currentPassword?: string;
      newPassword?: string;
      confirmPassword?: string;
    }
  >
) =>
  useMutation({
    mutationFn: async ({
      accessToken,
      currentPassword,
      newPassword,
      confirmPassword,
    }) => {
      invariant(accessToken);
      invariant(currentPassword);
      invariant(newPassword);
      invariant(confirmPassword);

      if (newPassword.length < 8) throw new Error(ERROR_PASSWORD_LENGTH);
      if (confirmPassword.length < 8) throw new Error(ERROR_CONFIRM_PASSWORD);
      if (newPassword !== confirmPassword)
        throw new Error(ERROR_PASSWORD_MISMATCH);

      const output = await commands.changePassword({
        accessToken,
        previousPassword: currentPassword,
        proposedPassword: newPassword,
      });

      invariant(output.$metadata.httpStatusCode === 200);
      return output;
    },
    ...options,
  });
