import { Box, Column, Row } from "./layout";
import { UIText } from "./ui-text";
import { Link } from "./link";
import { createGravatarUrl } from "utils/gravatar";
import { useAuth } from "contexts/auth/auth-context";

export const UserChip = () => {
  const auth = useAuth();
  if (auth.user)
    return (
      <Row
        alignItems="center"
        gap="1"
        borderRadius="999px"
        borderWidth="1px"
        borderStyle="solid"
        borderColor="bg.2"
        p="1"
      >
        <Box as="img" width="1" height="1" borderRadius="999px" src={createGravatarUrl(auth.user?.email)} />
        <Column>
          <UIText truncate={true} lineHeight="1">
            {auth.user?.username}
          </UIText>
          <UIText truncate={true} fontSize="1" lineHeight="1" color="text.4">
            {auth.user?.email ?? "None"}
          </UIText>
        </Column>
      </Row>
    );
  return <Link to="/login">Login</Link>;
};
