import { Outlet } from "react-router-dom";
import {
  IconCheckCircle,
  IconExclamationTriangle,
  IconPaperPlane,
  IconPhone,
} from "assets/icons";
import { UIText } from "components/ui-text";
import { Box, Column, Row } from "components/layout";
import { Link } from "components/link";
import QsicCombomarkUrl from "assets/svgs/qsic-combomark-dark.svg";
import { Icon } from "components/icon";
import { Breadcrumbs } from "../components/breadcrumbs";
import { useAuth } from "../contexts/auth/auth-context";
import { InfoWell } from "components/info-well";

export const AppLayout = () => {
  return (
    <Column flex="1">
      <Row p="3" justifyContent="center">
        <Box mr="2" as="img" src={QsicCombomarkUrl} />
      </Row>
      <Column
        width="100%"
        maxWidth="360px"
        alignSelf="center"
        justifySelf="center"
        flex="1"
        gap="2"
      >
        <Breadcrumbs />
        <Outlet />
        <Column mt="auto" p="2" gap="1">
          <UIText as="p" textAlign="center">
            Need help?
          </UIText>
          <Link to="tel:1300 113 279" aria-label="Call us">
            <Row gap="1">
              <Icon glyph={<IconPhone />} /> 1300 113 279
            </Row>
          </Link>
          <Link to="mailto:support@getqsic.com" aria-label="Email us">
            <Row gap="1">
              <Icon glyph={<IconPaperPlane />} />
              support@getqsic.com
            </Row>
          </Link>
        </Column>
      </Column>
    </Column>
  );
};

export const HomeView = () => {
  const auth = useAuth();
  if (auth.user) {
    return (
      <InfoWell
        icon={<IconCheckCircle />}
        iconColor="success"
        title="You're Logged In"
        actions={[
          <Link key="user" to="user">
            Profile
          </Link>,
        ]}
      />
    );
  }
  return (
    <InfoWell
      icon={<IconExclamationTriangle />}
      iconColor="warning"
      title="You're Not Logged In"
      actions={[
        <Link key="login" to="/login">
          Login
        </Link>,
      ]}
    />
  );
};
