import { QueryClient, QueryClientConfig } from "@tanstack/react-query";

export const defaultOptions: QueryClientConfig = {
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
};

export const client = new QueryClient(defaultOptions);
