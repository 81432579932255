import { IconSkullCrossbones } from "assets/icons";
import { Button } from "components/button";
import { InfoWell } from "components/info-well";
import { Column } from "components/layout";
import { ERROR_UNEXPECTED } from "strings";

export const ErrorView = (props: { retry?: () => void }) => {
  return (
    <Column flex="1" p="2">
      <InfoWell
        icon={<IconSkullCrossbones />}
        iconColor="error"
        title="Oops!"
        description={[ERROR_UNEXPECTED]}
        actions={
          props.retry
            ? [
                <Button key="retry" onClick={props.retry}>
                  Retry
                </Button>,
              ]
            : undefined
        }
      />
    </Column>
  );
};
