import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { commands } from "api/commands";
import { ACCESS_TOKEN_STORAGE_KEY } from "strings";
import invariant from "tiny-invariant";
import { getItem } from "utils/storage";

export const useLogoutMutation = (
  options?: UseMutationOptions<void, Error, void>
) =>
  useMutation({
    mutationFn: async () => {
      const accessToken = getItem<string>(ACCESS_TOKEN_STORAGE_KEY);
      if (!accessToken) return;
      const result = await commands.signOut({ accessToken });
      invariant(result.$metadata.httpStatusCode === 200);
    },
    ...options,
  });
