import { createRoot } from "react-dom/client";
import invariant from "tiny-invariant";
import { router } from "./router";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "./theme";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import "./sentry";
import { QueryClientProvider } from "@tanstack/react-query";
import { client } from "./api/query-client";
import { AuthProvider } from "./contexts/auth/auth-provider";
import { SuspenseBoundary } from "components/suspense-boundary";
import { LoadingView } from "views/fragments/loading";
import { ErrorView } from "views/fragments/error";

export const Routes = () => {
  return (
    <QueryClientProvider client={client}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <SuspenseBoundary
          loadingFallback={<LoadingView />}
          errorFallback={<ErrorView />}
        >
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </SuspenseBoundary>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

const container = document.getElementById("app");
invariant(container, "#app not found");
const root = createRoot(container);

root.render(<Routes />);
