import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { IconExclamationTriangle, IconSkullCrossbones } from "assets/icons";
import { Form } from "components/form";
import { InfoWell } from "components/info-well";
import { Column } from "components/layout";
import { UIText } from "components/ui-text";
import {
  ACCESS_TOKEN_STORAGE_KEY,
  ERROR_SONOS_LINK_CODE_EMPTY,
  ERROR_SONOS_LINK_CODE_HELP,
  REFRESH_TOKEN_STORAGE_KEY,
} from "strings";
import { getItem } from "utils/storage";
import { useSonosLinkMutation } from "api/queries/sonos";
import { UserChip } from "components/user";

export const SonosLinkView = () => {
  const [searchParams] = useSearchParams();
  const linkCode = searchParams.get("linkCode") ?? undefined;

  const navigate = useNavigate();
  const sonosLinkMutation = useSonosLinkMutation({
    onSuccess: () => {
      navigate("success");
    },
  });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const accessToken = getItem<string>(ACCESS_TOKEN_STORAGE_KEY);
    const refreshToken = getItem<string>(REFRESH_TOKEN_STORAGE_KEY);
    sonosLinkMutation.mutate({ accessToken, refreshToken, linkCode });
  };

  const location = useLocation();
  const handleClickLogout: React.MouseEventHandler<HTMLButtonElement> = () => {
    navigate(`/user/logout/?next=${location.pathname}${location.search}`);
  };

  return (
    <Column gap="2">
      <Column px="2" gap="2">
        <UIText as="h1" lineHeight="0">
          Link Sonos
        </UIText>
        <UIText lineHeight="2">Link your Sonos devices to your Qsic Music Service.</UIText>
      </Column>
      <Form aria-label="Link Sonos form" onSubmit={handleSubmit}>
        <Column gap="1">
          <label>Qsic Music Account</label>
          <UserChip />
          <button onClick={handleClickLogout}>Switch Accounts</button>
        </Column>
        {sonosLinkMutation.isError && (
          <InfoWell
            icon={<IconSkullCrossbones />}
            title="Error Submitting Link Code"
            iconColor="error"
            description={import.meta.env.DEV ? [sonosLinkMutation.error?.message] : undefined}
          />
        )}
        {!linkCode && (
          <InfoWell
            icon={<IconExclamationTriangle />}
            title="Missing Link Code"
            description={[ERROR_SONOS_LINK_CODE_EMPTY, ERROR_SONOS_LINK_CODE_HELP]}
            iconColor="warning"
          />
        )}
        {import.meta.env.DEV && (
          <Column gap="1">
            <label id="linkCode-field-label" htmlFor="linkCode-field">
              Sonos Link Code
            </label>
            <input
              id="linkCode-field"
              name="linkCode"
              defaultValue={linkCode ?? ""}
              placeholder="Sonos Link Code"
              readOnly={true}
              disabled={true}
            />
          </Column>
        )}
        <button type="submit" disabled={!linkCode}>
          Link Sonos
        </button>
      </Form>
    </Column>
  );
};
