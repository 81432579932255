import { Fragment } from "react";
import { Column, Row } from "./layout";
import { UIText } from "./ui-text";

export const InfoWell = (props: {
  title: string;
  description?: string[];
  icon?: React.ReactNode;
  iconColor?: string;
  actions?: React.ReactNode[];
}) => {
  return (
    <Column p="2" gap="1" bg="bg.0" borderRadius="1">
      <Row gap="1" alignItems="center">
        {props.icon && (
          <Column color={props.iconColor} fontSize="3">
            {props.icon}
          </Column>
        )}
        <UIText as="h1" fontSize="2" fontWeight="3">
          {props.title}
        </UIText>
      </Row>
      {props.description?.map((d) => (
        <UIText as="p" color="text.4" key={d} fontSize="1">
          {d}
        </UIText>
      ))}
      {props.actions && (
        <Row gap="1" mt="1">
          {props.actions.map((a, i) => (
            <Fragment key={i}>{a}</Fragment>
          ))}
        </Row>
      )}
    </Column>
  );
};
