import md5 from "md5";

export const createGravatarUrl = (
  emailAddress?: string
): string | undefined => {
  if (emailAddress && emailAddress.length > 0) {
    const id = md5(emailAddress.trim().toLocaleLowerCase());
    return `https://gravatar.com/avatar/${id}?d=mp`;
  }
};
