import { useNavigate, useSearchParams } from "react-router-dom";
import { Column } from "components/layout";
import { InfoWell } from "components/info-well";
import { IconSkullCrossbones } from "assets/icons";
import { Link } from "components/link";
import { storeTokens } from "utils/storage";
import { useLoginMutation } from "api/queries/login";
import { Form } from "components/form";
import { useQueryClient } from "@tanstack/react-query";
import { userQueryKeys } from "api/queries/user";
import { ERROR_LOGIN_FAILED } from "strings";

export const LoginView = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const client = useQueryClient();
  const loginMutation = useLoginMutation({
    onSuccess: async ({ AuthenticationResult, ChallengeName, Session }, { username }) => {
      const next = searchParams.get("next") ?? "/";
      if (ChallengeName === "NEW_PASSWORD_REQUIRED") {
        navigate(`challenge/?session=${Session}&username=${username}&next=${next}`);
      }
      if (AuthenticationResult) {
        storeTokens(AuthenticationResult.AccessToken, AuthenticationResult.RefreshToken);
        await client.invalidateQueries(userQueryKeys.user);
        navigate(next);
      }
    },
  });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const username = formData.get("username")?.toString();
    const password = formData.get("password")?.toString();
    loginMutation.mutate({ username, password });
  };

  return (
    <Column gap="2">
      {loginMutation.isError && (
        <InfoWell icon={<IconSkullCrossbones />} iconColor="error" title="Error" description={[ERROR_LOGIN_FAILED]} />
      )}
      <Form aria-label="Login form" onSubmit={handleSubmit}>
        <Column gap="1">
          <label htmlFor="username">Username</label>
          <input id="username" name="username" type="text" autoComplete="username" />
        </Column>
        <Column gap="1">
          <label htmlFor="password">Password</label>
          <input id="password" name="password" type="password" autoComplete="current-password" />
        </Column>
        <button type="submit" disabled={loginMutation.isLoading}>
          {!["loading", "success"].includes(loginMutation.status) && "Login"}
          {loginMutation.status === "loading" && "Logging In..."}
          {loginMutation.status === "success" && "Logged In!"}
        </button>
        <Link to="forgot-password">Forgot Password</Link>
      </Form>
    </Column>
  );
};
