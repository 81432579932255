import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { LoginChallengeView } from "views/login/challenge";
import { LoginView } from "views/login/login";
import { ForgotPasswordView } from "./views/login/forgot-password";
import { LogoutView } from "./views/user/logout/logout";
import { ChangePasswordView } from "views/user/change-password/change-password";
import { NotFoundView } from "./views/fragments/not-found";
import { AppLayout, HomeView } from "views/home";
import { wrapCreateBrowserRouter } from "@sentry/react";
import { SonosLinkView } from "./views/sonos/link/link";
import { SonosLinkSuccessView } from "./views/sonos/link/success";
import { UserView } from "./views/user/user";
import { SonosView } from "./views/sonos/sonos";
import { NotAuthorizedView } from "views/fragments/not-authorized";
import { AuthBoundary } from "contexts/auth/auth-provider";
import { ForgotPasswordConfirmView } from "views/login/forgot-password-confirm";

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AppLayout />} handle={{ crumb: "Home" }}>
      <Route index={true} element={<HomeView />} />
      <Route path="login" handle={{ crumb: "Login" }}>
        <Route index={true} element={<LoginView />} />
        <Route
          path="challenge"
          handle={{ crumb: "Set Password" }}
          element={<LoginChallengeView />}
        />
        <Route path="forgot-password" handle={{ crumb: "Forgot" }}>
          <Route index={true} element={<ForgotPasswordView />} />
          <Route
            path="confirm"
            handle={{ crumb: "Confirm" }}
            element={<ForgotPasswordConfirmView />}
          />
        </Route>
      </Route>
      <Route element={<AuthBoundary fallback={<NotAuthorizedView />} />}>
        <Route path="sonos" handle={{ crumb: "Sonos" }}>
          <Route index={true} element={<SonosView />} />
          <Route path="link" handle={{ crumb: "Link" }}>
            <Route index={true} element={<SonosLinkView />} />
            <Route
              path="success"
              element={<SonosLinkSuccessView />}
              handle={{ crumb: "Success" }}
            />
          </Route>
        </Route>
        <Route path="user" handle={{ crumb: "User" }}>
          <Route index={true} element={<UserView />} />
          <Route
            path="change-password"
            element={<ChangePasswordView />}
            handle={{ crumb: "Change Password" }}
          />
          <Route path="logout" handle={{ crumb: "Logout" }}>
            <Route index={true} element={<LogoutView />} />
          </Route>
        </Route>
      </Route>
      <Route path="/*" element={<NotFoundView />} />
    </Route>
  )
);
