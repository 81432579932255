import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";

Sentry.init({
  enabled: ["production", "staging"].includes(import.meta.env.MODE),
  environment: import.meta.env.VITE_ENVIRONMENT,
  release: import.meta.env.VITE_SENTRY_RELEASE,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  tracesSampleRate: 1.0,
});
