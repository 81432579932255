import {
  ACCESS_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from "../strings";

export const getItem = <T>(key: string, storage = window.localStorage) => {
  const item = storage.getItem(key);
  if (!item) return;
  return JSON.parse(item) as T;
};

export const setItem = <T>(
  key: string,
  value: T,
  storage = window.localStorage
) => {
  if (value === undefined || value === null) return;
  return storage.setItem(key, JSON.stringify(value));
};

export const removeItem = (key: string, storage = window.localStorage) => {
  return storage.removeItem(key);
};

export const getTokens = () => {
  return {
    accessToken: getItem<string>(ACCESS_TOKEN_STORAGE_KEY),
    refreshToken: getItem<string>(REFRESH_TOKEN_STORAGE_KEY),
  };
};

export const storeTokens = (accessToken?: string, refreshToken?: string) => {
  setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
  setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
};

export const removeTokens = () => {
  removeItem(ACCESS_TOKEN_STORAGE_KEY);
  removeItem(REFRESH_TOKEN_STORAGE_KEY);
};
