import { useUserQuery } from "api/queries/user";
import { AuthContextProvider, useAuth } from "./auth-context";
import { Outlet } from "react-router-dom";

export const AuthProvider = (props: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const userQuery = useUserQuery({
    useErrorBoundary: false,
  });
  return (
    <AuthContextProvider value={userQuery.data}>
      {props.children}
    </AuthContextProvider>
  );
};

export const AuthBoundary = (props: { fallback: React.ReactNode }) => {
  const auth = useAuth();

  if (auth.user) return <Outlet />;
  return <>{props.fallback}</>;
};
