import { Component as ReactComponent, Suspense, SuspenseProps } from "react";

interface ErrorBoundaryProps {
  fallback: React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
}

interface ErrorBoundaryState {
  error: unknown;
  hasError: boolean;
}

export class ErrorBoundary extends ReactComponent<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: unknown) {
    return { error, hasError: true };
  }

  componentDidCatch(error: unknown, info: unknown) {
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}

interface SuspenseBoundaryProps {
  loadingFallback: SuspenseProps["fallback"];
  errorFallback: ErrorBoundaryProps["fallback"];
  children: SuspenseProps["children"];
}

export const SuspenseBoundary = (props: SuspenseBoundaryProps) => {
  return (
    <ErrorBoundary fallback={props.errorFallback}>
      <Suspense fallback={props.loadingFallback}>{props.children}</Suspense>
    </ErrorBoundary>
  );
};
