import {
  createContext as createReactContext,
  useContext as useReactContext,
} from "react";

export const createContext = <C>(label?: string) => {
  const guardedContext = createReactContext<C | undefined>(undefined);
  const useGuardedContext = () => {
    const value = useReactContext(guardedContext);
    if (value === undefined) {
      throw new Error(
        `${label ?? "Unknown"} Context requires a Provider with a value`
      );
    }
    return value;
  };

  return [useGuardedContext, guardedContext.Provider] as const;
};
