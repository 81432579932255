import { NavLink, useMatches } from "react-router-dom";
import { Row } from "./layout";
import { UIText } from "./ui-text";
import { Fragment } from "react";

export const Breadcrumbs = () => {
  const matches = useMatches();
  const crumbs = (
    matches as ((typeof matches)[0] & { handle: { crumb?: string } })[]
  )
    .filter((match) => !!match.handle?.crumb)
    .map((match) => ({ label: match.handle.crumb, to: match.pathname }));

  return (
    <Row as="ol" gap="1" px="2">
      {crumbs.map((crumb, index) => (
        <Fragment key={crumb.label}>
          {index !== 0 && <UIText color="text.7">/</UIText>}
          <Row>
            <NavLink to={crumb.to}>
              <UIText>{crumb.label}</UIText>
            </NavLink>
          </Row>
        </Fragment>
      ))}
    </Row>
  );
};
