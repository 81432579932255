import {
  ConfirmForgotPasswordCommandOutput,
  ForgotPasswordCommandOutput,
  InitiateAuthCommandOutput,
  RespondToAuthChallengeCommandOutput,
} from "@aws-sdk/client-cognito-identity-provider";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { commands } from "api/commands";
import {
  ERROR_CONFIRM_PASSWORD,
  ERROR_LOGIN_FAILED,
  ERROR_MISSING_CONFIRMATION_CODE,
  ERROR_MISSING_USERNAME_PASSWORD,
  ERROR_PASSWORD_LENGTH,
  ERROR_PASSWORD_MISMATCH,
  REFRESH_TOKEN_STORAGE_KEY,
} from "strings";
import invariant from "tiny-invariant";
import { getItem } from "utils/storage";

export const useLoginMutation = (
  options?: UseMutationOptions<
    InitiateAuthCommandOutput,
    Error,
    { username?: string; password?: string }
  >
) =>
  useMutation({
    mutationFn: async ({ username, password }) => {
      invariant(username, ERROR_MISSING_USERNAME_PASSWORD);
      invariant(password, ERROR_MISSING_USERNAME_PASSWORD);
      const result = await commands.loginUsernamePassword({
        username,
        password,
      });
      invariant(result.$metadata.httpStatusCode === 200, ERROR_LOGIN_FAILED);
      return result;
    },
    ...options,
  });

export const useLoginChallengeMutation = (
  options?: UseMutationOptions<
    RespondToAuthChallengeCommandOutput,
    Error,
    {
      newPassword?: string;
      confirmPassword?: string;
      session?: string;
      username?: string;
    }
  >
) =>
  useMutation({
    mutationFn: async ({ newPassword, confirmPassword, username, session }) => {
      invariant(username);
      invariant(session);
      if (!newPassword?.length) throw new Error(ERROR_PASSWORD_LENGTH);
      if (newPassword !== confirmPassword)
        throw new Error(ERROR_PASSWORD_MISMATCH);
      const result = await commands.challengeChangePassword({
        session,
        username,
        proposedPassword: newPassword,
      });
      if (result.$metadata.httpStatusCode !== 200)
        throw new Error(ERROR_CONFIRM_PASSWORD);
      return result;
    },
    ...options,
  });

export const useLoginRefreshMutation = (
  options?: UseMutationOptions<InitiateAuthCommandOutput, Error, void>
) =>
  useMutation({
    mutationFn: async () => {
      const refreshToken = getItem<string>(REFRESH_TOKEN_STORAGE_KEY);
      invariant(refreshToken);

      const result = await commands.loginRefresh({
        refreshToken,
      });
      invariant(result.$metadata.httpStatusCode === 200);
      return result;
    },
    ...options,
  });

export const useLoginForgotPasswordMutation = (
  options?: UseMutationOptions<
    ForgotPasswordCommandOutput,
    Error,
    { username?: string }
  >
) =>
  useMutation({
    mutationFn: async ({ username }) => {
      invariant(username);
      const result = await commands.forgotPassword({ username });
      invariant(result.$metadata.httpStatusCode === 200);
      return result;
    },
    ...options,
  });

export const useLoginForgotPasswordConfirmMutation = (
  options?: UseMutationOptions<
    ConfirmForgotPasswordCommandOutput,
    Error,
    {
      username?: string;
      newPassword?: string;
      confirmPassword?: string;
      confirmationCode?: string;
    }
  >
) =>
  useMutation({
    mutationFn: async ({
      username,
      newPassword,
      confirmPassword,
      confirmationCode,
    }) => {
      if (!username) throw new Error(ERROR_MISSING_USERNAME_PASSWORD);
      if (!newPassword) throw new Error(ERROR_PASSWORD_LENGTH);
      if (!confirmPassword) throw new Error(ERROR_PASSWORD_LENGTH);
      if (newPassword !== confirmPassword)
        throw new Error(ERROR_PASSWORD_MISMATCH);
      if (!confirmationCode) throw new Error(ERROR_MISSING_CONFIRMATION_CODE);
      const result = await commands.forgotPasswordConfirm({
        username,
        password: newPassword,
        confirmationCode,
      });
      invariant(result.$metadata.httpStatusCode === 200);
      return result;
    },
    ...options,
  });
