export const AWS_REGION = import.meta.env?.VITE_AWS_REGION ?? "ap-southeast-2";
// export const AWS_APP_CLIENT_ID = import.meta.env?.VITE_AWS_APP_CLIENT_ID ?? "test-client-id";
// export const AWS_COGNITO_ENDPOINT = import.meta.env?.VITE_AWS_COGNITO_ENDPOINT ?? "test.cloudfront.net";

export const AWS_APP_CLIENT_ID =
  import.meta.env?.VITE_APP_ENV === "production" ? "2psbiluaqg0ri2i5jbqatcocu9" : "3ki5tiasp5i8t4h5gd7b5atk9";
export const AWS_COGNITO_ENDPOINT =
  import.meta.env?.VITE_APP_ENV === "production" ? "dd1pckdrkuin7.cloudfront.net" : "dnpwgphwtu6l1.cloudfront.net";
export const CORE_HOST =
  import.meta.env?.VITE_APP_ENV === "production"
    ? "https://core-api.getqsic.com"
    : "https://core-api.staging.getqsic.com";

export const ACCESS_TOKEN_STORAGE_KEY = `access_token`;
export const ID_TOKEN_STORAGE_KEY = `id_token`;
export const REFRESH_TOKEN_STORAGE_KEY = `refresh_token`;

export const ERROR_UNEXPECTED = "Something unexpected happened.";
export const ERROR_NOT_FOUND = "This page does not exist, or you do not have access right now.";
export const INFO_NOT_IMPLEMENTED = "This feature isn't implemented yet. Please check back soon.";

export const ERROR_CONFIRM_PASSWORD = "Please confirm your new password.";
export const ERROR_PASSWORD_MISMATCH = "Your passwords must match.";
export const ERROR_PASSWORD_LENGTH = "Your password must be at least 8 characters long.";
export const ERROR_MISSING_USERNAME_PASSWORD = "Please provide a username and password.";
export const ERROR_LOGIN_CHALLENGE_FAILED = "Something went wrong setting your new password.";
export const ERROR_LOGIN_FAILED = "Failed to login with provided credentials.";
export const SUCCESS_PASSWORD_CHANGED = "Your password has been updated.";

export const ERROR_FORGOT_PASSWORD_FAILED = "Failed to start password recovery.";
export const ERROR_MISSING_CONFIRMATION_CODE = "Please provide your confirmation code.";
export const ERROR_FORGOT_PASSWORD_CONFIRM = "Failed to update your password.";

export const ERROR_REFRESH_FAILED = "Failed to refresh your session.";
export const INFO_AUTH_EXPIRED = "Please reauthenticate your session";
export const INFO_AUTH_EMPTY = "Please login to view this page.";

export const ERROR_SONOS_LINK_CODE_EMPTY = "You need a link code to connect your Sonos to Qsic.";
export const ERROR_SONOS_LINK_CODE_HELP = "Return to the Sonos app and try setup again.";
export const SUCCESS_SONOS_LINKED = "Your Sonos system is now connected to your Qsic account.";

export const CONFIRM_LOGOUT_EVERYWHERE = "Logging out here will deauthorize any connected apps using your Qsic account";
