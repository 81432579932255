import { useLoginForgotPasswordConfirmMutation } from "api/queries/login";
import { IconSkullCrossbones } from "assets/icons";
import { Form } from "components/form";
import { InfoWell } from "components/info-well";
import { Column } from "components/layout";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ERROR_FORGOT_PASSWORD_CONFIRM } from "strings";

export const ForgotPasswordConfirmView = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const forgotPasswordConfirmMutation = useLoginForgotPasswordConfirmMutation({
    onSuccess: () => {
      navigate("/login");
    },
  });
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    forgotPasswordConfirmMutation.mutate({
      username: formData.get("username")?.toString(),
      newPassword: formData.get("new-password")?.toString(),
      confirmPassword: formData.get("confirm-password")?.toString(),
      confirmationCode: formData.get("confirmation-code")?.toString(),
    });
  };
  return (
    <Column gap="2">
      {forgotPasswordConfirmMutation.isError && (
        <InfoWell
          icon={<IconSkullCrossbones />}
          iconColor="error"
          title="Error"
          description={[
            ERROR_FORGOT_PASSWORD_CONFIRM,
            forgotPasswordConfirmMutation.error?.message,
          ]}
        />
      )}
      <Form onSubmit={handleSubmit}>
        <Column gap="1">
          <label htmlFor="username">Username</label>
          <input
            id="username"
            name="username"
            type="text"
            autoComplete="username"
            defaultValue={searchParams.get("username")?.toString() ?? ""}
          />
        </Column>
        <Column gap="1">
          <label htmlFor="new-password">New Password</label>
          <input
            id="new-password"
            name="new-password"
            type="password"
            autoComplete="new-password"
          />
        </Column>
        <Column gap="1">
          <label htmlFor="confirm-password">Confirm Password</label>
          <input
            id="confirm-password"
            name="confirm-password"
            type="password"
            autoComplete="new-password"
          />
        </Column>
        <Column gap="1">
          <label htmlFor="confirmation-code">Confirmation Code</label>
          <input
            id="confirmation-code"
            name="confirmation-code"
            type="text"
            autoComplete="confirmation-code"
          />
        </Column>
        <button
          type="submit"
          disabled={forgotPasswordConfirmMutation.isLoading}
        >
          Change Password
        </button>
      </Form>
    </Column>
  );
};
