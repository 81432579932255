import styled, { keyframes } from "styled-components";
import { IconSpinnerThird } from "assets/icons";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  animation-name: ${spin};
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform-origin: center;
  font-size: 1.5rem;
`;

export const IconLoading = () => (
  <Spin>
    <IconSpinnerThird />
  </Spin>
);
