import { IconLoading } from "components/icon-loading";
import { Box, Column, Row } from "components/layout";
import { UIText } from "components/ui-text";

export const LoadingView = () => {
  return (
    <Column p="3" alignItems="center" justifyContent="center" flex="1">
      <Row gap="1" alignItems="center">
        <Box flex="0 0 auto">
          <IconLoading />
        </Box>
        <UIText>Loading...</UIText>
      </Row>
    </Column>
  );
};
