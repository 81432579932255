import {
  AuthFlowType,
  ChallengeNameType,
  ChangePasswordCommand,
  ConfirmForgotPasswordCommand,
  ForgotPasswordCommand,
  GetUserCommand,
  GlobalSignOutCommand,
  InitiateAuthCommand,
  RespondToAuthChallengeCommand,
  RevokeTokenCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { AWS_APP_CLIENT_ID } from "../strings";
import { client as cognitoClient } from "./cognito-client";

const loginRefresh = async ({ refreshToken }: { refreshToken: string }) =>
  cognitoClient.send(
    new InitiateAuthCommand({
      AuthFlow: AuthFlowType.REFRESH_TOKEN_AUTH,
      AuthParameters: { REFRESH_TOKEN: refreshToken },
      ClientId: AWS_APP_CLIENT_ID,
    })
  );

const loginUsernamePassword = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}) =>
  cognitoClient.send(
    new InitiateAuthCommand({
      AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
      AuthParameters: { USERNAME: username, PASSWORD: password },
      ClientId: AWS_APP_CLIENT_ID,
    })
  );

const getUser = async ({ accessToken }: { accessToken: string }) =>
  cognitoClient.send(
    new GetUserCommand({
      AccessToken: accessToken,
    })
  );

const challengeChangePassword = async ({
  session,
  username,
  proposedPassword,
}: {
  session: string;
  username: string;
  proposedPassword: string;
}) =>
  cognitoClient.send(
    new RespondToAuthChallengeCommand({
      ChallengeName: ChallengeNameType.NEW_PASSWORD_REQUIRED,
      Session: session,
      ChallengeResponses: {
        NEW_PASSWORD: proposedPassword,
        USERNAME: username,
      },
      ClientId: AWS_APP_CLIENT_ID,
    })
  );

const forgotPassword = async ({ username }: { username: string }) =>
  cognitoClient.send(
    new ForgotPasswordCommand({
      Username: username,
      ClientId: AWS_APP_CLIENT_ID,
    })
  );

const forgotPasswordConfirm = async ({
  username,
  password,
  confirmationCode,
}: {
  username: string;
  password: string;
  confirmationCode: string;
}) =>
  cognitoClient.send(
    new ConfirmForgotPasswordCommand({
      Username: username,
      ConfirmationCode: confirmationCode,
      Password: password,
      ClientId: AWS_APP_CLIENT_ID,
    })
  );

const changePassword = async ({
  accessToken,
  previousPassword,
  proposedPassword,
}: {
  accessToken: string;
  previousPassword: string;
  proposedPassword: string;
}) =>
  cognitoClient.send(
    new ChangePasswordCommand({
      AccessToken: accessToken,
      PreviousPassword: previousPassword,
      ProposedPassword: proposedPassword,
    })
  );

export const revokeToken = async ({ refreshToken }: { refreshToken: string }) =>
  cognitoClient.send(
    new RevokeTokenCommand({ ClientId: AWS_APP_CLIENT_ID, Token: refreshToken })
  );

const signOut = async ({ accessToken }: { accessToken: string }) =>
  cognitoClient.send(new GlobalSignOutCommand({ AccessToken: accessToken }));

export const commands = {
  loginRefresh,
  loginUsernamePassword,
  getUser,
  challengeChangePassword,
  forgotPassword,
  forgotPasswordConfirm,
  changePassword,
  signOut,
};
